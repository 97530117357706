import { isMobile } from '@global-js/utils/browser';
import { ajaxHTML } from '@global-js/utils/ajax';
import { getCookies, createCookie } from '@global-js/utils/cookies';
import { getCountLoadedPage } from '@global-js/utils/countPageLoaded';

const ALREADY_ASK_NOTIFICATIONS = 'lumni_already_ask_notifications';
const ASK_NOTIFICATIONS_DURATION = 90;

const stickyBannerNewsletter = document.querySelector('.sticky-banner.tags-notifications');
const btnCloseBanner = document.querySelector('.sticky-banner.tags-notifications .ignore-button');
const establishments = [
  {
    type: 'primaire',
    levels: [
      { level: 'maternelle', optin: false },
      { level: 'cp', optin: false },
      { level: 'ce1', optin: false },
      { level: 'ce2', optin: false },
      { level: 'cm1', optin: false },
      { level: 'cm2', optin: false },
    ],
  },
  {
    type: 'college',
    levels: [
      { level: 'sixieme', optin: false },
      { level: 'cinquieme', optin: false },
      { level: 'quatrieme', optin: false },
      { level: 'troisieme', optin: false },
    ],
  },
  {
    type: 'lycee',
    levels: [
      { level: 'seconde', optin: false },
      { level: 'premiere', optin: false },
      { level: 'terminale', optin: false },
    ],
  },
];

const initActivateNotifications = () => {
  document.getElementById('activate-notif').addEventListener('click', () => {
    // eslint-disable-next-line no-undef
    UA.then((sdk) => {
      if (sdk.canRegister && sdk.isSupported) {
        sdk.register().then(() => {
          window.location.reload();
        });
      }
    });
  });
};

/* eslint-disable no-restricted-syntax */
export const updateTagsNotifications = async () => {
  // eslint-disable-next-line no-undef
  const sdk = await UA;
  const contact = await sdk.contact;

  for (const establishment of establishments) {
    const current = [];
    const currentToRemove = [];

    for (const level of establishment.levels) {
      const currentLevel = document.getElementById(`level-${level.level}`);
      if (currentLevel.checked) {
        current.push(level.level);
      } else {
        currentToRemove.push(level.level);
      }
    }

    contact.editTags()
      .add(establishment.type, current)
      .remove(establishment.type, currentToRemove)
      .apply();

    window.localStorage.setItem(`tags_${establishment.type}`, JSON.stringify(current));
  }
};
/* eslint-enable no-restricted-syntax */

const getTemplateNotifications = (optin) => {
  ajaxHTML(`${window.location.origin}/ajax/notification-tags`, 'post', {
    optin,
    permission: Notification.permission,
    establishments,
  }, false, 'no-cache')
    .then((response) => response.text())
    .then((template) => {
      const element = document.querySelector('#notification-tags');

      if (template !== '') {
        element.innerHTML = template;
      }

      if (optin === false) {
        initActivateNotifications();
      } else {
        const validatePushNotif = document.getElementById('validate-switch-notif');

        validatePushNotif?.addEventListener('click', () => {
          updateTagsNotifications();
          window.localStorage.setItem('confirm_tags_notifications', true);
          window.location = document.referrer;
        });
      }
    })
    .catch((e) => console.log(e.message));
};

function gestureToGetChannelId(channelId) {
  let userGestures = [];
  const konamiCodeGestures = [
    'level-maternelle', 'level-cm2', 'level-ce1',
  ];
  window.addEventListener('touchend', (event) => {
    userGestures.push(event.target.htmlFor);
    if (JSON.stringify(userGestures) === JSON.stringify(konamiCodeGestures)) {
      document.getElementById('notification-tags').insertAdjacentHTML(
        'afterend',
        `<div id="channel-info">Channel ID: ${channelId}</div>`,
      );
      userGestures = [];
    }
  });
}

export const getTagsNotifications = () => {
  // eslint-disable-next-line no-undef
  UA?.then(async (sdk) => {
    const channelId = await sdk?.channel.id();
    if (channelId === null) {
      getTemplateNotifications(false);
    } else {
      window.localStorage.setItem('channel_id', channelId);
      establishments.forEach((etablissement) => {
        etablissement.levels.forEach((level) => {
          const tagsStorage = window.localStorage.getItem(`tags_${etablissement.type}`);
          tagsStorage?.includes(level.level)
            ? level.optin = true
            : level.optin = false;
        });
      });
      getTemplateNotifications(true, establishments);
      gestureToGetChannelId(channelId);
    }
  });
};

const openBannerNotifConfirmation = () => {
  stickyBannerNewsletter ? stickyBannerNewsletter.setAttribute('data-active', 'true') : '';
};

const closeBannerNotifConfirmation = () => {
  stickyBannerNewsletter ? stickyBannerNewsletter.setAttribute('data-active', 'false') : '';
};

function closeStickyBannerNewsletter(popin) {
  popin ? popin.setAttribute('data-active', 'false') : '';
  createCookie(ALREADY_ASK_NOTIFICATIONS, true, ASK_NOTIFICATIONS_DURATION);
  popin?.classList.add('hidden');
}

const isPermissionGranted = () => Notification.permission === 'granted';

export async function initNotificationsPrompt() {
  if (
    isMobile()
    && getCountLoadedPage() > 2
    && !getCookies([ALREADY_ASK_NOTIFICATIONS]).lumni_already_ask_notifications
    && !isPermissionGranted()
    && 'Notification' in window
  ) {
    const promptNotificationsBanner = document.querySelector('.prompt_notifications');
    promptNotificationsBanner.setAttribute('data-active', 'true');
    const ignoreNotifications = promptNotificationsBanner?.querySelector('.ignore-button');
    const enableNotifications = promptNotificationsBanner?.querySelector('.enable-button');

    [ignoreNotifications, enableNotifications].forEach((selector) => {
      if (selector) {
        selector.addEventListener('click', () => {
          closeStickyBannerNewsletter(promptNotificationsBanner);
          const action = selector.getAttribute('data-action') || '';
          // _dispatchEventsClick(action);
          if (action === 'enable' && 'Notification' in window) {
            // eslint-disable-next-line no-undef
            UA.then((sdk) => {
              if (sdk.canRegister && sdk.isSupported) {
                sdk.register().then(() => {
                  window.location.href = '/notifications';
                });
              }
            });
          }
        });
      }
    });
  }
}

export const initBannerNotifConfirmation = () => {
  setTimeout(() => {
    if (
      window.localStorage.getItem('confirm_tags_notifications')
    ) {
      openBannerNotifConfirmation();
      localStorage.removeItem('confirm_tags_notifications');
    }

    [btnCloseBanner].forEach((item) => {
      if (item) {
        item.addEventListener('click', () => {
          closeBannerNotifConfirmation();
        });
      }
    });
  }, 100);
};

export const registerPushWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      // Check if the message type is 'notificationClick'
      if (event.data.type === 'notificationClick') {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const notificationData = event.data.data;
        if (notificationData) {
          const dataNotif = {
            src_push: `${notificationData.title} - ${notificationData.body}`,
            src_id_push: null,
            src_date_heure_d_ouverture: `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`,
            src_offre: '8',
            src_medium: '12',
            src_campaign: 'Push notification',
            src_platform: '23',
          };
          document.dispatchEvent(new CustomEvent('Tracking:click', {
            detail: {
              type: 'notifications_click',
              feature: 'notifications_click',
              data: dataNotif,
            },
          }));
        }
      }
    });
  }
};
