const labels = {
  prev: 'précédente',
  next: 'suivante',
  first: 'Aller à la première page',
  last: 'Aller à la dernière page',
};

const _getLink = (url, attr, label) => `<a href=${url} rel="${attr}" aria-label="${label}" class="pagination__item pagination__item--${attr}-page"></a>`;

const _toggleLink = (pagination, element, attr) => {
  pagination
    .querySelector(`.pagination__item--${attr}-page`)
    .classList
    .toggle(
      'pagination__item--disabled',
      element.classList.contains('pagination__item--disabled'),
    );
};

const _addAriaLabel = (element, attr) => {
  element.setAttribute('aria-label', `Aller à la page ${attr}`);
};

export const Pagination = () => {
  const pagers = document.querySelectorAll('.pagerfanta-v3');

  if (pagers.length === 0) {
    return;
  }

  pagers.forEach((pagination) => {
    if (parseInt(pagination.dataset.total, 10) > 1 && !(pagination.querySelector('.pagination__item--last-page') || pagination.querySelector('.pagination__item--first-page'))) {
      const prev = pagination.querySelector('.pagination__item--previous-page');
      const next = pagination.querySelector('.pagination__item--next-page');

      const baseUrl = pagination.querySelector('a.pagination__item').href.split('?')[0];
      const lastUrl = `${baseUrl}?page=${pagination.dataset.total}`;

      prev.innerHTML = '';
      next.innerHTML = '';
      pagination.insertAdjacentHTML('afterbegin', _getLink(baseUrl, 'first', labels.first));
      pagination.insertAdjacentHTML('beforeend', _getLink(lastUrl, 'last', labels.last));

      _toggleLink(pagination, prev, 'first');
      _toggleLink(pagination, next, 'last');
      _addAriaLabel(prev, labels.prev);
      _addAriaLabel(next, labels.next);
    }
  });
};
